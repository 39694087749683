import { form } from '../api'

export const scrollToTop = () => {
	const wrapper: any = document && document.getElementsByTagName('html')[0]

	if (wrapper) {
		wrapper.scrollLeft = 0
		wrapper.scrollTop = 0
	}
}

export const uploadDocument = async (file: object, header: object) => {
	const { data } = await form.uploadFormAttachment(file, header)
	return data
}

export const scrollToElement = (selector: string, offset: number = 0) => {
	const element = document.querySelector(selector)
	if (element) {
		const elementPosition = element.getBoundingClientRect().top
		const offsetPosition = elementPosition - offset

		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth"
		})
	}
}
