export enum FORM_TYPE {
	CONTACT = 'CONTACT',
	NEWSLETTER = 'NEWSLETTER',
	TREES_INSTITUTION = 'TREES_INSTITUTION',
	TREES_VOLUNTEERING = 'TREES_VOLUNTEERING',
	TREES_CUSTOM_CONTRIBUTION = 'TREES_CUSTOM_CONTRIBUTION',
	TREES_ADOPT_A_TREE = 'TREES_ADOPT_A_TREE',
	DYNAMIC = 'DYNAMIC',
	WHERE_TO_PLANT = 'WHERE_TO_PLANT'
}

export enum FILTERS {
	MAP = 'FILTERS/MAP'
}

